<template>
  <div>
    
    <div class="tabBox user">
      <div style="display: flex">
        <h2 class="bold" style="display: inline-block">{{ $t("livemap-data-list-title") }}</h2>
        <p style="display: inline-block; margin-left: 16px;">{{ $t("data-total") }} {{total}} {{ $t("overall-data-event-ea") }}</p>
      </div>
      <div class="boxCol2-2 flexB">
        <div class="map">
          <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" />
          <MapButtonComponent ref="buttonMap" />
          <button class="point medium" style="width:120px; position: absolute; left: 42%; bottom: 16px;"  @click="handleDetail">
            <a href="javascript:void(0);">{{ $t("btn-detail") }}</a>
          </button>
        </div>
        <div class="tableWrap box one" style="border: none; padding: 0 16px;">
          <div class="tableBox" style="float: right; height: 100%;">
            <table>
              <tr>
                <th>No</th>
                <th>{{ $t("livemap-data-way-name") }}</th>
                <th>{{ $t("livemap-data-way-dept") }}</th>
                <th>{{ $t("livemap-data-way-operator") }}</th>
                <th>{{ $t("livemap-data-way-startdate") }}</th>
              </tr>
              <template v-if="total > 0">
                <tr v-for="(data, i) in liveMapList" :key="i" @click="handleSelectEl(data)"  :class="{ active: selectEl != null && selectEl.waypointLogId == data.waypointLogId }">
                  <td>{{ total - (currentPage * size + i) }}</td>
                  <td>{{ data.title}}</td>
                  <td>{{ data.userDepartmentName }}</td>
                  <td>{{ data.userName }}</td>
                  <td>{{ moment(data.startDate).format("YYYY.MM.DD HH:mm")}}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="5" style="text-align: center;">
                    데이터가 없습니다.
                  </td>
                </tr>
              </template>
            </table>
            <div class="pagination">
              <el-pagination
                  small
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage + 1"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
div.liveMapMark {
  position: absolute;
  width: 60px;
  height: 18px;
  right: 12px;
  top: 9px;
  padding: 2px 0;
  text-align: center;
  background-color: #ffc002;
  border-radius: 9px;
}
span.markTxt {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}
</style>
<script>
import moment from "moment";
import {fetchWaypointLocationList} from  "@/api/missionWaypointLocation";
import { fetchEvent } from "@/api/event";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import { fetchWaypointLogList } from "@/api/missionWaypointLog";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  components: { Map, MapButtonComponent},
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      currentPage: 0,
      total: 0,
      size:15,
      selectEl : null,
      liveMapList: [],
      loadMap : 'kakao',
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    this.getWaypointLogList();
    this.getEvent();
    this.initButtonMap()
  },
  methods: {
    initButtonMap() {
      if (this.$refs.buttonMap) {
        this.$refs.buttonMap.showMapTypeChange();
      } else {
        console.error('buttonMap reference not found');
      }
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.onMapCenter();
        if(this.selectEl != null) {
          this.getWaypointLocationList();
        }
      })
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    
    handleDetail() {
      if(this.selectEl != null) {
        this.$emit("select-live-map", this.selectEl.waypointId, this.selectEl.waypointLogId, this.selectEl.contentsId)
      }
    },
    handleSelectEl(data){
      this.$refs.map.onClearMapData();
      if(this.selectEl != null && this.selectEl.waypointLogId == data.waypointLogId) {
        this.selectEl = null;
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat);
        return;
      }
      this.selectEl = data;
      this.getWaypointLocationList();
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    async getWaypointLogList() {
      let params = {
        page_page: this.currentPage,
        page_sort : "startDate,DESC",
        eventId : this.eventId,
        isLiveMap : true,
        isThermal : false,
        isStartTime : true
      };

      await fetchWaypointLogList(params).then((res) => {
        this.total = res.data.data.totalElements;
        this.liveMapList = res.data.data.content;
      });
    },
    getWaypointLocationList(){
      let params = {
        eventId : this.eventId,
        waypointId : this.selectEl.waypointId
      };

      fetchWaypointLocationList(params).then((res) => {
        if (res.data.result == 0) {
          var waypointLocationList = res.data.data.content;
          let sort = waypointLocationList.sort((a,b) => {return a.index - b.index})
          let mapDataType = MapDataType.ID_WAYPOINT
          if( sort.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

            sort.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            })
            this.createMapLine("way-point", sort);
          } else {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getContentsList();
    },
  },
};
</script>
