<template>
  <div ref="rootDiv">
    <div class="video-player" style="height: 100%; width: 100%;" ref="video-container">

      <div id="info-live-root" v-if="isShowLiveText" class="livePopMark" style="display :block">
        <img src="@/assets/images/icon_svg/ic_live_mark.svg"/>
      </div>

      <!-- 사진캡처 버튼 -->
      <div ref="vodScreenShot" class="vodDown flex"  v-show="isPlayStatus && isLiveButtonShow" :style="{'right' : captureRight + 'px'}" @click="onVideoCapture">
        <img src="@/assets/images/icon_svg/ic_screen_shot.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>
      <!-- 동영상 전체 화면 만들기 -->
      <div class="vodDown flex" v-if="isLiveButtonShow && isPlayStatus" :style="{'right' : expandRight + 'px'}" @click="onLiveExpand">
        <img src="@/assets/images/icon_svg/ic_full_bk.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
      </div>

      <!-- 전체 팍업 모달 화면 -->
      <div v-if="playerId != undefined && isModelButtonShow" @click="openFullScreenModal" class="right-bottom-fullscreen">
        <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
      </div>

      <!-- 오른쪽 하단 스피커버튼 -->
      <!-- 드론은 소리x -->
      <div v-if="deviceType !== 'Drone' && isSpeakerButtonShow" @click="isSoundToggle" class="soundBox" >
        <img class="speakerSize" v-if="!muted" src="@/assets/images/icon_svg/ic_speaker_on.png" alt="icon" />
        <img class="speakerSize"  v-else src="@/assets/images/icon_svg/ic_speaker_off.png" alt="icon" />
      </div>

      <!-- AI 선택 버튼 (유저 라이센스에 따라 열림) -->
      <div v-if="false" ref="aiSelect" v-show="( this.$router.currentRoute.name=='vodPlay' || isAiMarkShow) && isPlayStatus" class="vod-ai-mark" id="ai-mark-root">
        <div class="vod-ai-btn">
          <input type="radio" v-model="selectAiDetecting" name="detecting" value ="object">
          <label>객체 감지</label>
          <input type="radio" v-model="selectAiDetecting" name="detecting" value ="fire" >
          <label>불 감지</label>
          <input type="radio" v-model="selectAiDetecting" name="detecting" :value ="null" >
          <label style="margin-right: 0px;">감지 해제</label>
        </div>
      </div>

      <div class="webrtc-container video-js">
        <video ref="wowzaWebRTCVideo" id="video" tabindex="-1" style="height: 100%; width: 100%; position: absolute; top: 0;left: 0; object-fit: cover; overflow: clip;" :muted="muted" autoplay></video>
      </div>
    </div>
  </div>
</template>
<script>
//https://github.com/WowzaMediaSystems/webrtc-examples

import moment from "moment/moment";
// import icLiveMark from "@/assets/images/icon_svg/ic_live_mark.svg";

// import Settings from './webrtc/lib/Settings.js';
import WowzaWebRTCPlay from './webrtc/lib/WowzaWebRTCPlay.js';


// function getLiveTitleShow(isVod, currentRouteName){
//   let className = currentRouteName == "livePlay" ? "livePlay" : "";
//   let root = `<div id="info-live-root" class="livePopMark ${className}" style="display: ${isVod ?'none':'block'};">
//       <img src="${icLiveMark}"/>
//     </div>`
//   let parser = new DOMParser();
//   let doc = parser.parseFromString(root, "text/html");
//   return doc.getElementById("info-live-root");
// }


export default {
  name :"WebRTC_WOWZA",
  props: {
    playerId : {
      type: String,
      default: null,
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    deviceType: {
      type: String,
      default: null
    },
    channelIndex: {
      type: Number,
      default: null
    },
    // 현재 streamId(playerId)와, 선택한 streamId를 비교해서 소리를 들리게할지말지 결정
    selectStreamId: {
      type : String,
    },

    isShowLiveText : {
      type: Boolean,
      default: true,
    },
    //region 버튼 확인
    isSpeakerButtonShow: {
      type: Boolean,
      default: true,
    },
    isModelButtonShow: {
      type: Boolean,
      default: true,
    },
    isLiveButtonShow: {
      type : Boolean,
      default : false,
    },
    isAiMarkShow:{
      type : Boolean,
      default : false,
    },
    isSpeakerTc:{
      type : Boolean,
      default : true
    },
    //endregion

    volumeNumber: {
      type : Number,
      default: 0.5
    },

    //wowza
    sdpUrl: {
      type: String,
      default: null, // wss://dw.teixon.com:1935/webrtc-session.json
    },
    applicationName: {
      type: String,
      default: null,
    },
    streamName: {
      type: String,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      isLoadedmetadata : false,
      isDelete : !this.isUser,
      isDownload : this.isVod,
      isExpand : !this.isVod,
      isPlayStatus : false,
      selectAiDetecting : null,
      errorCount : 0,
      liveLoadError : false,

      buttonDefaultRight: 10,
      buttonMargin : 16,
      buttonSize : 32,

      isFullScreen : false,
      muted : true,

      soundPrefix: this.deviceType == "Drone" || !this.isSpeakerTc ? "" : "webrtc_",
      wowzaWebRTCPlayer : null,
      wowzaWebRTCOption : {
        sdpURL: null,
        applicationName: null,
        streamName: null,
        ip : null,
        secret: null,
        timeout:null,
        prefix:null,
      },
    };
  },
  computed : {
    captureRight: function () {
      var right = 0;
      if(this.isVod) {
        right = this.buttonDefaultRight;
        if(this.isDelete && this.isDeleteButtonShow) {
          right = right + this.buttonMargin + this.buttonSize;
        }
        if(this.isDownload) {
          right = right + this.buttonMargin + this.buttonSize;
        }

      } else {
        right = this.buttonDefaultRight;
        if(this.isExpand) {
          right = right + this.buttonMargin + this.buttonSize;
        }
      }

      if(this.isFullScreen) {
        right = 0;
      }

      if(right == 0){
        right = this.buttonDefaultRight;
      }
      return right;
    },
    deleteRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    downloadRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = right + this.deleteRight;
        right = right + this.buttonMargin + this.buttonSize;
      }
      if(right == 0) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    expandRight: function () {
      var right = 0;
      if(this.isExpand) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
  },
  mounted() {
    let refVideo = this.$refs.wowzaWebRTCVideo;
    if(refVideo != undefined) {
      this.wowzaWebRTCPlayer = new WowzaWebRTCPlay();
      this.wowzaWebRTCPlayer.on({
        onError: this.onWebRTCError,
        onStateChanged: this.onWebRTCState,
      });
      this.wowzaWebRTCPlayer.set({videoElementPlay:refVideo,});

      if(this.sdpUrl != null && this.applicationName != null && this.streamName != null) {
        this.wowzaWebRTCOption.sdpURL = this.sdpUrl;
        this.wowzaWebRTCOption.applicationName = this.applicationName;
        this.wowzaWebRTCOption.streamName = this.soundPrefix + this.streamName;
        this.onWebRTCChangeOption().then(() => {
          this.wowzaWebRTCPlayer.play();
        })
      }

      document.addEventListener("fullscreenchange", this.onfullscreenchange);
    }
  },
  watch: {
    sdpUrl(){
      // console.log('sdpUrl',this.sdpUrl)
      this.isPlayStatus = false;
      this.wowzaWebRTCOption.sdpURL = this.sdpUrl;
      this.wowzaWebRTCOption.applicationName = this.applicationName;
      this.wowzaWebRTCOption.streamName = this.soundPrefix + this.streamName;
      this.onWebRTCChangeOption().then(() => {
        this.wowzaWebRTCPlayer.play();
      })
    },
    selectStreamId: function(newValue){
      if(this.playerId != newValue ) {
        this.mute()
      } else {
        this.unMute()
      }
    },
  },
  methods: {
    onWebRTCChangeOption(){
      // console.log('onWebRTCChangeOption',this.wowzaWebRTCOption);
      return this.wowzaWebRTCPlayer.set(this.wowzaWebRTCOption);
    },

    onWebRTCError(error){
      let message;
      if ( error.message ) {
        message = error.message;
      }
      else {
        message = error
      }
      message;
      this.isLoadedmetadata = false;
      this.isPlayStatus = false;
      this.liveLoadError = true
      this.onPlayerReloadTimer();

    },
    //connectionState "stopped" "connected"
    onWebRTCState(state){
      switch (state.connectionState) {
        case 'connected':
          // console.log('onWebRTCState connected',state)
          this.errorCount = 0;
          this.liveLoadError = false
          this.isLoadedmetadata = true;
          this.isPlayStatus = true;
          state.playing = true;
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
          break;
        case 'stopped':
          // console.log('onWebRTCState stopped',state)
          this.isLoadedmetadata = false;
          state.playing = false;
          this.isPlayStatus = false;
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
          break;
        case 'disconnected' :
          // console.log('onWebRTCState stopped',state)
          this.isLoadedmetadata = false;
          state.playing = false;
          this.isPlayStatus = false;
          this.$emit('playerRemoveId',this.playerId)
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
          break;
      }
      // console.log("onWebRTCState", this.isPlayStatus)
    },
    ///와우자

    onfullscreenchange() {
      this.isFullScreen = false;
      if(document.fullscreenElement != null) {
        this.isFullScreen = true;
      }
      // if(this.$refs.vodScreenShot != undefined) {
      //   if(this.isFullScreen) {
      //     this.$refs.wowzaWebRTCVideo.appendChild(this.$refs.vodScreenShot);
      //   } else {
      //     this.$refs.rootDiv.appendChild(this.$refs.vodScreenShot);
      //   }
      // }

      // let infoLiveRoot = this.$refs.rootDiv.querySelector('#info-live-root');

      if(this.playerAddDom != null) {
        if( this.isFullScreen == false) {
          // this.$refs.rootDiv.appendChild(this.playerAddDom);
          // infoLiveRoot.classList.remove('fullscreen');
        } else {
          // infoLiveRoot.classList.add('fullscreen');
          // document.fullscreenElement.appendChild(infoLiveRoot);
        }
      }
    },
    onPlayerAddDom(addId) {
      //player에 dom 추가 함수
      this.playerAddDom = document.querySelector(`#${addId}`);
      if(this.playerAddDom != undefined) {
        this.$refs["video-container"].appendChild(this.playerAddDom);
        // this.$refs.rootDiv.appendChild(this.playerAddDom);
      }
    },
    onPlayerReloadTimer(){

      setTimeout(() => {
        this.reloadPlayer();
      }, 3000);
    },
    getVideo() {
      if(this.sdpUrl != this.wowzaWebRTCOption.sdpURL || this.applicationName != this.wowzaWebRTCOption.applicationName || this.streamName != this.wowzaWebRTCOption.streamName) {
        this.wowzaWebRTCOption.sdpURL = this.sdpUrl;
        this.wowzaWebRTCOption.applicationName = this.applicationName;
        this.wowzaWebRTCOption.streamName = this.soundPrefix + this.streamName;
        this.onWebRTCChangeOption().then(() => {
          this.wowzaWebRTCPlayer.play();
        })
      }
    },
    onPlayerRemove(){
      this.$emit('playerRemoveId',this.playerId)
    },
    reloadPlayer(){
      if(this.errorCount > 4) {
        return
      }

      if(this.errorCount > 3) {
        this.onPlayerRemove();
      }

      if(this.liveLoadError == true) {
        this.errorCount++;
        this.wowzaWebRTCOption.sdpURL = null;
        this.wowzaWebRTCOption.applicationName = null;
        this.wowzaWebRTCOption.streamName = null;
        this.isPlayStatus = false;
        this.getVideo();
      }
    },
    onLiveExpand(){
      // let player = this.$refs.wowzaWebRTCVideo;
      let playerContainer = this.$refs["video-container"]
      if(playerContainer.requestFullscreen){
        console.log("1")
        playerContainer.requestFullscreen();
      } else if(playerContainer.webkitRequestFullscreen){
        console.log("2")
        playerContainer.webkitRequestFullscreen();
      } else if(playerContainer.mozRequestFullscreen){
        console.log("3")
        playerContainer.mozRequestFullscreen();
      }
    },
    onVideoCapture(){
      let videoElement = this.$refs.wowzaWebRTCVideo;
      let canvas = document.createElement("canvas");
      canvas.crossOrigin = "anonymous";
      let ctx = canvas.getContext('2d');

      canvas.width = 640;
      canvas.height = 360;

      ctx.fillRect(0,0,canvas.width,canvas.height);
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height );
      ctx.crossOrigin = "anonymous";

      var link = document.createElement('a');
      link.download = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
    },
    openFullScreenModal() {
      this.$emit('playerOpen',"live", this.playerId,this.channelIndex)
    },
    isSoundToggle(){
      this.muted = !this.muted
      this.$emit("toggleSound",this.playerId)
    },
    webrtcMuteVolume(){
      this.$refs.wowzaWebRTCVideo.muted = this.muted;
      this.$refs.wowzaWebRTCVideo.volume = this.muted ? 0 : this.volumeNumber;
    },
    mute(){
      this.muted = true
      this.webrtcMuteVolume();
    },
    unMute(){
      this.muted = false
      this.webrtcMuteVolume();
    },
  },
  beforeDestroy() {
    if(this.wowzaWebRTCPlayer != null) {
      this.wowzaWebRTCPlayer.stop();
    }
  },
};

</script>

<style>
/*비디오 다운로드*/
.vodDown{
  width: 36px;
  height: 36px;
  text-align: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  padding: 6px;
  color:black;
  border: 1px solid white;
}
.vodDown:hover{
  background: #94d9ef;
}
.right-bottom-fullscreen {
  position: absolute;
  bottom:10px;
  right: 10px;
  z-index: 10;
  line-height: 28px;
}
.soundBox {
  position: absolute;
  bottom:10px;
  right: 40px;
  z-index: 10;
  line-height: 28px;
}


.speakerSize {
  width: 20px;
}

.vjs-fullscreen > #info-live-root > img {
  width: 8rem;
}

.livePlay > img {
  width: 6rem;
}
.livePopMark > img{width: 45px;}

.webrtc-container {
  padding-top: 50.25%;
  height: auto;
  width: 100%;
  position: relative;
  display: block;
}

</style>
