<template>
  <div class="contents liveInfo">
    <div class="new_title">
      <h1>{{ $t("site-control-main-sub-info") }}</h1>
    </div>
    <div class="flexB info-page">
      <!-- new info content -->
      <div class="info-content">
        <div class="info-content-row">
          <div class="info-site">
            <div class="info-detail">
              <div class="info-site-img">
                <img src="@/assets/images/thum_s5.png">
              </div>
              <div class="info-site-title">
                <img v-if="eventObj.status == 'Ready' " class="statusImg" src="@/assets/images/icon_svg/ic_waiting.svg" />
                <img v-else-if="eventObj.status == 'Close'" class="statusImg" src="@/assets/images/icon_svg/ic_close.svg" />
                <img v-else-if="eventObj.status == 'Open'" class="statusImg" src="@/assets/images/icon_svg/ic_on_going.svg" />
                <h2>{{ eventObj.title }}</h2>
                <h4>(No.{{ eventObj.viewId != null ? eventObj.viewId : eventObj.eventId }})</h4>
              </div>
              <ul class="info-site-address">
                <li>
                  <span>{{$t("event-data-site-addr")}}</span>
                  <h4>{{ eventObj.address + eventObj.addressDetail }}</h4>
                </li>
                <li>
                  <span>{{$t("site-view-data-summary-period")}}</span>
                  <h4>
                    <span v-if="eventObj.status == 'Ready'">{{$t("event-data-status-ready")}}</span>
                    <span v-if="eventObj.status == 'Open'">{{ moment(eventObj.openDate).format("YYYY.MM.DD HH:mm") }} - {{ $t("event-data-status-on-going")}}</span>
                    <span v-if="eventObj.status == 'Close'">{{ moment(eventObj.openDate).format("YYYY.MM.DD HH:mm") }} - {{ moment(eventObj.closeDate).format("YYYY.MM.DD HH:mm") }} / 총 {{elapsedDay}} 일</span>
                  </h4>
                </li>
                <li>
                  <span>{{$t("site-view-data-summary-charge")}}</span>
                  <h4>
                      {{ eventObj.managerDepartment ? eventObj.managerDepartment.name : "-" }}
                    / {{ eventObj.managerName }}
                    / {{
                      eventObj.managerPhone
                          ?  getMask(eventObj.managerPhone)
                          : "-"
                    }}<br>
                    / {{ eventObj.managerEmail ? eventObj.managerEmail : "-" }}
                  </h4>
                </li>
              </ul>
            </div>
            <div class="info-number">
              <ul>
                <li>
                  <span>{{ accountId=="komipo"? "로봇": $t("site-view-title-drone") }}</span>
                  <h4>{{deviceJoinCount}}<span>{{ $t("overall-data-device-ea") }}</span></h4>
                </li>
                <li>
                  <span>{{$t("site-view-title-operator")}}</span>
                  <h4>{{ controllerJoinCount }}<span>{{ $t("overall-data-member-ea") }}</span></h4>
                </li>
                <li>
                  <span>{{$t("site-view-title-user")}}</span>
                  <h4>{{ workerJoinCount }}<span>{{ $t("overall-data-member-ea") }}</span></h4>
                </li>
                <li>
                  <span>{{ accountId=="komipo"? "로봇운행": $t("data-drone-flight") }}</span>
                  <h4>{{ deviceLogCount }}<span>{{ $t("overall-data-num-ea")}}</span></h4>
                </li>
                <li>
                  <span>{{ $t("data-member-chk-in") }}</span>
                  <h4>{{ workerLogCount }}<span>{{ $t("overall-data-num-ea")}}</span></h4>
                </li>
                <li>
                  <span>LIVE 촬영</span>
                  <h4>{{assignCount}}<span>{{ $t("overall-data-num-ea")}}</span></h4>
                </li>
                <li>
                  <span>영상</span>
                  <h4>{{ vodCount }}<span>{{ $t("data-default-ea") }}</span></h4>
                </li>
              </ul>
            </div>
          </div>
          <div class="info-notice">
            <h2>{{$t("site-view-summary-event-memo")}}</h2>
            <div class="notice-box">
              <p>{{eventObj.memo}}</p>
            </div>
            <button class="medium" v-if="userType == 'Admin'" @click="handleTab">{{ $t("btn-register") }}</button>
          </div>
        </div>
        <div class="info-table">
          <h2>현장 작업내역</h2>
          <div class="info-table-box">
            <table class="workerTable">
              <tr>
                <th><p>{{ $t("site-view-data-summary-work-date") }}</p></th>
                <th style="background: #f4f4f4;"><p>{{ accountId=="komipo"? "로봇 수": $t("site-view-data-summary-work-drone") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-work-operator") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-work-flight") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-work-participation-member") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-participation-num") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-work-live") }}</p></th>
                <th><p>{{ $t("site-view-data-summary-work-vod") }}</p></th>
              </tr>
              <tr v-for="data in eventJoinCountList" :key="data.date" >
                <td>{{moment(data.date).format("YYYY-MM-DD")}}</td>
                <td>{{data.deviceJoinCount}}</td>
                <td>{{data.controllerJoinCount}}</td>
                <td>{{data.deviceLogCount}}</td>
                <td>{{data.userJoinCount}}</td>
                <td>{{data.workerLogCount}}</td>
                <td>{{data.liveCount}}</td>
                <td>{{data.contentsCount}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="info-map">
        <h2>현장 위치 정보</h2>
        <div class="homeMap" style="width: 100%; height: calc(100% - 50px); margin: 0; position:relative">
            <Map 
            ref="map" 
            :loadMapName="loadMap" 
            @ready-map = "readyMap" 
            style="width: 100%; height: 100%; z-index: 2; position: relative;"/>
          
          <MapButtonComponent
            ref="buttonMap"
            class="info_buttonMap"
            />
        </div>
      </div>
      <transition name="fade">
        <div v-if="visible == 1" class="dim on">
          <SiteMemo @close="close" @submit="addMemo" :memo = "eventObj.memo"/>
        </div>
        <div v-if="visible == 2" class="dim on">
        </div>
      </transition>
    </div>
  </div>
</template>
<style>

.statusImg{
  margin-left: 1rem;
  margin-right: 3.3rem;
}
.liveInfo .info th:nth-child(1),
.liveInfo .info th:nth-child(2){ padding: 0 10px;}
.infoTable > tr > td > th{ height: 32px;}

.info-page{display: flex;justify-content: space-between;align-items: stretch !important;}

.info-content{margin-right: 20px;width: 65%; }
.info-content h1 { margin-bottom: 10px;}
.info-content-row{display: flex;justify-content: space-between;align-items: stretch;margin-bottom: 20px;}
.info-site{position: relative; background: #fff;width: 65%;margin-right: 20px; padding: 15px;display: flex;justify-content: space-between;align-items: stretch;border-radius: 5px;}
.info-site::before{content: '';position: absolute;top:0;left: 0;  width: 0;height: 0;border-bottom: 50px solid #8dbaff;border-top: 50px solid transparent;border-left: 50px solid #8dbaff;border-right: 50px solid transparent;    border-radius: 5px;
    transform: rotate(90deg);}
.info-detail{width: 55%;margin-right: 20px;}

.info-site-img{width: 120px;height: 120px;border-radius: 150px;margin: auto;background: #eee;margin-top: 30px;margin-bottom: 20px;}
.info-site-img > img{width: 100%;height: 100%;border-radius: 150px;}

.info-site-title{text-align: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.info-site-title > img{margin: 0;margin-bottom: 5px;width: 50px;}
.info-site-title > h2{font-size: 1.6rem;font-weight: bold;margin-bottom: 0;}
.info-site-title > h4{font-size: 1.2rem;font-weight: 400;}

.info-site-address{padding: 15px 0 0 0;}
.info-site-address > li{display: flex;justify-content: space-between;align-items: flex-start;margin-bottom: 15px;}
.info-site-address > li:last-child{margin-bottom: 0;}
.info-site-address > li > span{padding: 2px 20px;background: #E8EBEF;color: #969696;border-radius: 10px;font-size: 1.2rem;}
.info-site-address > li > h4{font-size: 1.2rem;font-weight: 400;letter-spacing: -1px;text-align: right;}
.info-site-address > li > h4 > span{font-size: 1.2rem;}

.info-number{padding: 15px;background: #f7f7f7;width: 40%;display: flex;align-items: stretch;}
.info-number > ul{display: flex;flex-wrap: wrap;align-items: center;}
.info-number > ul > li{width: 50%;margin-bottom: 20px;}
.info-number > ul > li:last-child{margin-bottom: 0px;}
.info-number > ul > li > span{font-size: 1.2rem;margin-bottom: 15px;display: block;color: #969696;}
.info-number > ul > li > h4{font-size: 1.6rem;}
.info-number > ul > li > h4 > span{font-size: 1.4rem;margin-left: 5px;}

.info-notice{width: 35%;background: #fff;border-radius: 5px;padding: 15px;;}
.info-notice > h2{font-size: 1.4rem;font-weight: bold;}
.info-notice > .notice-box{width: 100%;height: 300px;max-height: 300px;overflow-y: scroll;background: #f7f7f7;margin-bottom: 15px;padding: 15px;}
.info-notice > .notice-box > p{color: #454545;font-size: 1.2rem;}
.info-notice > button{width: 100%;border: 1px solid #0080FF;border-radius: 5px;color: #ffffff;background: #0080FF;}
.info-notice > button:hover{width: 100%;border: 1px solid #0e73c4;border-radius: 5px;color: #ffffff;background: #0e73c4 !important;}

.info-table{background: #fff;padding: 15px;border-radius: 5px;}
.info-table > h2{font-size: 1.4rem;font-weight: bold;}
.info-table > .info-table-box{height: 300px; max-height: 300px;overflow-y: scroll;}

.info-map{width: 34%;background: #fff;padding: 15px;border-radius: 5px;}
.info-map > h2{font-size: 1.4rem;font-weight: bold;}

</style>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { fetchEvent, updateMemo } from "@/api/event";
import {fetchEventJoinList,} from "@/api/dashboard";
import {fetchWorkerLogList} from "@/api/worker";
import {fetchDeviceLogList} from "@/api/device";
import { fetchVodList } from "@/api/contents";
import { format } from "@/mixins/format";
import SiteMemo from "@/components/pop/SiteMemo";
import {fetchChannelAssignList} from "@/api/channel";
import {fetchStatisticsEventJoinCountList} from "@/api/statistics";
import {getDefaultMapZoomLevel} from "@/util/common";

import Map from "@/components/map/Map";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  components:{
    SiteMemo,
    Map,
    MapButtonComponent
  },
  mixins: [format],
  data() {
    return {
      moment: moment,
      id: "",
      eventObj: {},
      department: {},
      visible: false,
      memo: "",
      eventInfo : null,

      vodCount: 0,
      assignCount : 0,

      deviceLogCount: 0,
      workerLogCount: 0,

      workerJoinCount:0,
      controllerJoinCount:0,
      deviceJoinCount:0,

      eventJoinCountList : [],
      elapsedDay : 0,

      loadMap : 'kakao',

    };
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
    }),
    /*eventMemo() {
      var arr = this.eventObj.memo.split(".");

      return this.eventObj.memo.replace(".", "<br />")
    }*/
  },
  created() {
    this.id = this.$route.params.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 1 });
  },
  mounted() {
    this.getEvent();
    this.getVodCount();
    this.getLiveAssignCount();
    this.getJoinCount();
    this.getLogCount();
    this.initButtonMap()
  },
  methods: {
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
    },
    readyMap(){
      this.initMapData(this.currentLang );
    },
    async initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      if(this.eventInfo != null){
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
      }
    },

    handleTab() {
      this.visible = 1;
    },
      goHome() {
      this.$emit("goHome");
    },
    
    copyLink() {
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = document.getElementById("copyTag").textContent;

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
      alert(this.$t("865"));
    },
    getEvent() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfolat != "") {
            let location = {
              lng : this.eventInfo.lng,
              lat : this.eventInfo.lat,
            }
            if(this.$refs.map != undefined) {
              this.createMapMarker(MapDataType.ID_SITE, "MapMarker", "", location, false);
              this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            }
          } else {
            if(this.$refs.map != undefined) {
              this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
            }
          }
        }
        
        if(this.$refs.map != undefined) {
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        }

        this.eventObj = res.data.data;
        this.memo = this.eventObj.memo;

        if(this.eventObj.status == "Close"){
          let close = new Date(this.eventObj.closeDate);
          let open = new Date(this.eventObj.openDate);

          const elapsedMSec = close.getTime() - open.getTime();
          this.elapsedDay =  Math.floor(elapsedMSec / 1000 / 60 / 60 / 24);
        }
        this.getEventJoinCountList();
      });
    },
    
    createMapMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    getEventJoinCountList() {
      let params = {
      }
      params["dateRange.from"] = null;
      params["dateRange.to"] = null;

      if(this.eventObj.status == 'Open'){
        this.startYmd = this.eventObj.openDate;
        this.endYmd = Date();
      } else if(this.eventObj.status == 'Close'){
        this.startYmd = this.eventObj.openDate;
        this.endYmd = this.eventObj.closeDate;
      }

      if (this.startYmd != null && this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }

      fetchStatisticsEventJoinCountList(this.id, params).then((res) => {
        if (res.data.result == 0) {
          this.eventJoinCountList = res.data.data.reference
        }
      });
    },
    getJoinCount() {
      let paramsWorker = {
        pageSize : 1,
        eventId : this.id,
        workerTypes : 'Worker'
      }
      fetchEventJoinList('worker',paramsWorker).then((res) => {
        this.workerJoinCount = res.data.data.total
      });
      let paramsController = {
        pageSize : 1,
        eventId : this.id,
        workerTypes : 'Controller'
      }
      fetchEventJoinList('worker',paramsController).then((res) => {
        this.controllerJoinCount = res.data.data.total
      });

      let paramsDevice = {
        pageSize : 1,
        eventId : this.id,
      }
      fetchEventJoinList('device',paramsDevice).then((res) => {
        this.deviceJoinCount = res.data.data.total
      });
    },

    getLogCount() {
      let paramsWorker = {
        pageSize : 1,
        eventId : this.id,
        isEndTime : true,

      }
      fetchWorkerLogList(paramsWorker).then((res) => {
        this.workerLogCount = res.data.data.total
      });

      let paramsController = {
        pageSize : 1,
        eventId : this.id,
        isEndTime : true,
        isStartTime : true,
      }
      fetchDeviceLogList(paramsController).then((res) => {
        this.deviceLogCount = res.data.data.total
      });
    },
    getVodCount() {
      let params = {
        eventId: this.id,
        pageSize: 1,
      };
      fetchVodList(params).then((res) => {
        this.vodCount = res.data.data.total;
      });
    },
    getLiveAssignCount(){
      let params = {
        eventId: this.id,
        pageSize: 1,
        isUnassigned : true,
      };
      fetchChannelAssignList(params).then((res) => {
        this.assignCount = res.data.data.total;
      });
    },

    close() {
      this.visible = false;
    },
    addMemo(data) {
      this.visible = false;
      this.memo = data;
      updateMemo(this.id, this.memo).then((res) => {
        if (res.data.result == 0) {
          this.eventObj.memo = this.memo;
          return alert(this.$t("alert-message-process-save"));
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
