<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <div class="new_title">
      <h1>{{ $t("site-control-menu-point") }}</h1>
    </div>
    
    <div class="search-from">
      <h2>상세검색</h2>
      <div class="search-from-input">
        <h4>{{ $t("search-keyword-title") }}</h4>
        <div class="search-input">
        <select v-model="keywordType">
          <option value="all">{{ $t("search-keyword-all") }}</option>
          <option value="registerUserName">{{ $t("point-data-registrant") }}</option>
          <option value="memo">{{ $t("point-data-memo") }}</option>
        </select>
        <input
            type="text"
            v-model="keyword"
            @keydown.enter="logSearch"
        />
        </div>
      </div>
      <div class="search-from-input">
        <h4>{{ $t("search-regdate-title") }}</h4>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
      <div class="search-from-btn">
        <button class="point medium" @click="logSearch"  style="width: 49%;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
          {{ $t("btn-searching") }}
        </button>
        <button class="medium margin6" @click="reset" style="width: 49%;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">
          {{ $t("btn-reset") }}
        </button>
      </div>
    </div>

    <div class="points-list">
      <div class="points-list-header">
        <h2>{{ $t("point-data-list") }}</h2>
        <button class="point-add-btn" @click="pointCreateShow">{{ $t("point-data-add") }}</button>
      </div>
      <div class="points-list-content">
        <p>{{ $t("data-total") }}<span> {{ pointCount }}</span> {{ $t("overall-data-event-ea") }}</p>
        <div class="points-list-scroll">
          <div class="points-list-none"  v-if="pointCount == 0">
            {{ $t("search-not-found") }}
          </div>
          <div class="points-list-box" v-for="(data, i) in pointList" :key="i" @click="handleSelectEl(data)"  :class="{ active: selectEl != null && selectEl.index == data.index }">
            <div class="point-type">
              <span>{{ pointCount - i }}</span>
              <h4>{{ $t(getPointTypeName(data.type)) }}</h4>
            </div>
            <h2>{{ data.memo}}</h2>
            <h4 style="font-size: 1.2rem;"><span>{{ data.registerUserName}}</span>│<span>{{ moment(data.registerDate).format("YYYY.MM.DD HH:mm")}}</span></h4>
            <div class="point-del" @click="$event.cancelBubble = true">
              <template v-if="data.isOwner">
                  <button v-if="eventInfo != null && eventInfo.status != 'Close'" class="medium" style="padding: 0 20px;" @click="onPointDelete(data)">{{ $t("btn-delete") }}</button>
                </template>
                <template v-if="!data.isOwner">
                  <span>-</span>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map" style="width: calc(78% - 45px);height: calc(100% + 50px);position: absolute;right: 0;bottom: 0" >
      <Map ref="map"  style="width: 100%; height: 100%;"  :loadMapName="loadMap" @item-click="onMapItemClick"/>
      <MapButtonComponent class="point-map-button" ref="buttonMap"/>
    </div>
  </div>
</template>

<style>
.search-from{width: 22%;background: #fff;border-radius: 5px;padding: 15px;margin-bottom: 15px;}
.search-from > h2{font-size: 1.4rem;font-weight:bold;border-bottom: 1px solid #ddd;padding-bottom: 5px;margin-bottom: 5px;}

.search-from-input{display: flex;flex-direction: column;width: 100%;margin-bottom: 5px;}
.search-from-input > h4{font-size: 1.2rem;font-weight: 400;color: #969696;margin-bottom: 5px;}
.search-from-input > .search-input{display: flex;margin-bottom: 5px;}
.search-from-input > .search-input > select{width: 30%;border-radius: 5px;margin-right: 5px;}
.search-from-input > .search-input > input{width: 69%;border-radius: 5px;margin-bottom: 0px;}
.search-from-input > .vdp-datepicker{width: 100%;}
.search-from-input > .vdp-datepicker input{width: 100%;border-radius: 5px;}
.search-from-btn{display: flex;flex-direction: row;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;}

.points-list{width: 22%;height: calc(100% - 350px);background: #fff;border-radius: 5px;padding: 15px;}
.points-list-header{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.points-list-header > h2{font-size: 1.4rem;font-weight: bold;margin: 0;}
.points-list-header > .point-add-btn{border: 1px solid #0080FF;font-size: 1.2rem;font-weight: 400;color: #0080FF;border-radius: 5px;padding: 0 15px;height: 30px;}

.points-list-content{height: 100%;}
.points-list-content > p{font-size: 1.2rem;margin-bottom: 5px;}
.points-list-content > p > span{color: #0080FF;}
.points-list-scroll{height: calc(100% - 80px);overflow-y: scroll;}
.points-list-box{border: 1px solid #ddd;padding: 10px;margin-bottom: 10px;border-radius: 5px;}
.points-list-box.active{border: 1px solid #0080FF;background: #f7fbff;}
.points-list-box:hover{border: 1px solid #0080FF;cursor: pointer;}
.point-type{display: flex;align-items: center;margin-bottom: 5px;}
.point-type > span{background: #0080FF;width: 20px;height: 20px;color: #fff;text-align: center;padding-top: 2px;border-radius: 20px;font-size: 1.2rem;}
.point-type > h4{font-size: 1.2rem;padding: 2px 10px;background: #f80;color: #fff;border-radius: 10px;margin-left: 5px;font-weight: 400;}
.points-list-box > h2{font-size: 1.4rem;font-weight: 500;margin-bottom: 5px;}
.points-list-box > h4{margin-bottom: 0px;}
.points-list-box > h4 > span{font-size: 1.2rem;color: #969696;font-weight: 400;}

.point-del{text-align: right;}
.point-del > button{border: 1px solid #ddd;height: 30px;border-radius: 5px;color: #969696;font-weight: 300;background: #fff;}
.point-del > span{font-size: 1.2rem;color: #969696;}

.points-list-none{font-size: 1.6rem;text-align: center;padding-top: 20rem;}

.point-map-button {
  position: absolute;
  top: 50px;
  right: 5px;
}

</style>

<script>
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapMarkerInfoData from "@/components/map/data/MapMarkerInfoData";

import {  fetchPointModify, fetchPointDelete, fetchPointList, fetchPointCheckUpdate, getPointTypeName } from "@/api/point";
import { format } from "@/mixins/format";
import {fetchEvent} from "@/api/event";
import moment from "moment";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
  name: "Home",
  components: { Map, Datepicker ,MapButtonComponent},
  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    selectEl : {
      deep : true,
      handler(data) {
        var isSelected = data != null
        var type = null;
        if(data != null) {
          type = this.getSelectTypeToMapDataType(data.selectType);
        }
        this.createMapInfo(type, data, isSelected);
      },
    },
  },
  data() {
    return {
      moment: moment,
      pointList: [],
      pointCount: 0,
      total: 0,

      windowHeight: window.innerHeight,
      typeChangeUpShowHeight: 0,

      userId : localStorage.getItem("userId"),
      showType: "POINT",
      checkBoxType: "POINT",
      interval: null,
      selectEl: null,
      mapInfoOldData : null,
      selectPointEl:[""],
      pointServerTime: null,
      eventInfo : null,
      loadMap : 'kakao',

      ko: ko,

      //검색 조건
      startYmd: null,
      endYmd: null,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      statuses: null,
      keyword: null,
    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
    }),
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 7 });
    window.addEventListener('resize', this.handleResize);
    this.typeChangeUpShowHeight = this.windowHeight - 340;
  },
  async mounted() {
    this.initButtonMap() 
    await this.getEvent();
    this.handleShowType();
    this.interval = setInterval(() => {
    this.getEventPoint();
    
      // this.handleShowType();
    }, 2000);
  },
  methods: {
    initButtonMap() {
      this.$refs.buttonMap.showMapPoint()
      this.$refs.map.onSetIsMarkerClick();
    },
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeUpShowHeight = this.windowHeight - 340;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.$nextTick(function () {

        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        
        if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
        
        this.pointServerTime = null;
        this.getEventPoint();
      })
    },
    async getEvent() {
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },


    getPointTypeName(type) {
      return getPointTypeName(type);
    },

    getSelectTypeToMapDataType(selectType){
      var mapDataType = null;
      if(selectType == null || selectType == undefined) {
        return mapDataType;
      }

      switch (selectType){
        case "Drone" :
          mapDataType = MapDataType.ID_DRONE;
          break;
        case "Worker" :
          mapDataType = MapDataType.ID_USER;
          break;
        case "Controller" :
          mapDataType = MapDataType.ID_CONTROLLER;
          break;
        case "Point" :
          mapDataType = MapDataType.ID_POINT;
          break;
        case "Station" :
          mapDataType = MapDataType.ID_STATION;
          break;
      }
      return mapDataType;
    },

    handleShowType() {
      this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, true);
    },

    //정보 표시 추가
    createMapInfo(mapDataType, data, isSelected){
      if(this.mapInfoOldData != null) {
        this.$refs.map.onInfoMarkerRemove(this.mapInfoOldData.type, "event_info");
      }

      if(data == null) {
        return;
      }

      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = "event_info";
      mapMarkerData.lng = data.lng;
      mapMarkerData.lat = data.lat;

      let mapMarkerInfoData = new MapMarkerInfoData();
      mapMarkerInfoData.mapDataType = mapDataType;

      switch (mapDataType) {
        case MapDataType.ID_DRONE :
          mapMarkerInfoData.type = MapDataType.ID_MAP_INFO_DRONE;
          mapMarkerInfoData.setDroneInfoData(data);
          break;
        case MapDataType.ID_USER :
        case MapDataType.ID_CONTROLLER :
          mapMarkerInfoData.type = MapDataType.ID_MAP_INFO_USER;
          mapMarkerInfoData.setUserInfoData(data);
          break;
        case MapDataType.ID_POINT:
          mapMarkerInfoData.typeName = this.$t(getPointTypeName(data.type));
          mapMarkerInfoData.type = MapDataType.ID_POINT;
          mapMarkerInfoData.name = this.$t(getPointTypeName(data.type));
          mapMarkerInfoData.setPointInfoData(data);
          break;
        default :
          mapMarkerInfoData = null;
      }

      if(mapMarkerInfoData != null) {
        if(isSelected) {
          this.mapInfoOldData = mapMarkerInfoData;
          this.$refs.map.onInfoMarkerShow(mapMarkerInfoData.type, mapMarkerData, mapMarkerInfoData, this.mapItemClickListener);
        }
      }
    },

    onMapItemClick(id,type,isMain){
      isMain;
      type;
      console.log("??!")
      let findIndex = this.pointList.findIndex((item) => item.selectId == id);
      if(findIndex != -1) {
        let findData = this.pointList[findIndex];
        this.handleSelectEl(findData);
      }
    },

    mapItemClickListener(data, isSave){
      var filter = this.pointList.filter((item)=> item.selectId == data.selectId);
      var filterData = null;
      if(filter.length > 0) {
        filterData = filter[0];
      }

      if(isSave && data.mapDataType == "ID_POINT") {
        if(filterData != null) {
          filterData.memo = data.memo;
          filterData.editMode = false;
          this.onPointModify(filterData);
        }
      }

      if(!isSave) {
        this.handleSelectEl(filterData);
      }
    },

    handleUnSelectEl(){
      var sendSelect = this.selectEl;
      this.selectEl = null;
      this.handleSelectAction(sendSelect, false);
    },
    handleSelectAction(data, isSelected){
      var mapMarkerData = new MapMarkerData();
      mapMarkerData.id = data.selectId;
      mapMarkerData.pointType = data.type;
      mapMarkerData.mapDataType = MapDataType.ID_POINT;
      mapMarkerData.setPointData(data);

      this.$refs.map.onMarkerIsSelected(mapMarkerData.mapDataType, mapMarkerData, isSelected);

      if(isSelected) {
        this.$refs.map.onLngLatToMoveCenter(data.lng, data.lat);
        this.$refs.map.onSetLngLat(data.lng,data.lat);
      } else {
        this.$refs.map.onSetLngLat(null,null);
      }

    },
    handleSelectEl(data) {
      if(this.selectEl != null && this.selectEl.selectId == data.selectId) {
        this.handleUnSelectEl();
        return;
      }

      if(this.selectEl != null) {
        this.handleUnSelectEl();
      }
      this.selectEl = data;
      this.handleSelectAction(this.selectEl, true);
    },

    createMapMarker(mapDataType , id, location){
      let pointMapMarker = new MapMarkerData();
      pointMapMarker.id = id;
      pointMapMarker.lng = location.lng;
      pointMapMarker.lat = location.lat;
      if(mapDataType == MapDataType.ID_DRONE) {
        pointMapMarker.rotate = location.azimuth;
      }
      if(this.$refs.map.onIsMarker(mapDataType, id ) ) {
        this.$refs.map.onModifyMarkerAt(mapDataType, pointMapMarker);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, pointMapMarker);
      }
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "all";
    },

    logSearch(){
      this.currentPage = 0;
      this.pointServerTime = null;
      this.getEventPoint();
    },

    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      if(this.pointServerTime != null) {
        params.serverTime = moment(this.pointServerTime).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      if(this.pointServerTime == null) {
        await fetchPointList( params).then((res) => {
          if (res.data.result == 0) {
            this.pointServerTime = res.data.data.serverTime
            this.pointCount = res.data.data.total
            this.pointList = res.data.data.content
            this.pointList.forEach((element) => {
              element.selectId = element.index;
              element.selectType = "Point";
              element.isOwner = this.userType != "User" ? true : this.userId == element.registerUserId;
              let pointMapMarker = new MapMarkerData();
              element.name = this.$t(getPointTypeName(element.type));
              pointMapMarker.setPointData(element);
              this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
            })
          }
        });
      } else {
        await fetchPointCheckUpdate(this.eventId, params).then((res) => {
          if (res.data.result == 0) {
            this.pointServerTime = res.data.data.serverTime
            var pointList = res.data.data.content
            pointList.map((item) =>{
              item.selectId = item.index;
              item.selectType = "Point";
              item.name = this.$t(getPointTypeName(item.type));
              item.isOwner = this.userType != "User" ? true : this.userId == item.registerUserId;
            });

            const deleteList = pointList.filter((element) => element.status == "Delete" || element.status == "Deactivate");
            const newList = pointList.filter((element) => {
              var isFindIndex = (data) => {
                return data.index == element.index;
              };
              var find = this.pointList.findIndex(isFindIndex);
              return element.status == "Activate" && find == -1;
            });

            deleteList.forEach((element)=>{
              var isFindIndex = (data) => {
                return data.index == element.index;
              };
              var find = this.pointList.findIndex(isFindIndex);
              if(find != -1) {
                this.pointList.splice(find,1);
              }
            })

            newList.forEach((element)=>{
              element.isOwner = this.userType != "User" ? true : this.userId == element.registerUserId;
              let pointMapMarker = new MapMarkerData();
              pointMapMarker.setPointData(element);
              this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
              this.pointList.unshift(element)
            })
            this.pointCount = this.pointList.length;
            if(this.visible == this.showType[3]){
              this.total = this.pointCount;
            }
          }
        });
      }
    },
    pointCreateShow(){
      mapButtonEventBus.$emit("toggleMapPoint", true)
    },
    onPointDelete(data){
      if(!confirm(this.$t("point-delete-alert-title"))){
        return;
      }
      fetchPointDelete(data.index, this.eventId).then((res) => {
        if (res.data.result == 0) {
          var isFindIndex = (source) => {
            return source.index == data.index;
          };

          var find = this.pointList.findIndex(isFindIndex);
          if(find != -1) {
            this.pointList.splice(find,1);
          }

          this.$refs.map.onDeleteMarkerAt(MapDataType.ID_POINT, data.index);

          if(this.selectPointEl.indexOf(data) > 0) {
            this.$refs.map.onPointModify(null);
          }

          this.pointCount = this.pointList.length;
          if(this.visible == this.showType[3]){
            this.total = this.pointCount;
          }
        }
      });
    },
    
    onPointModify(data){
      var sendData = data;
      fetchPointModify(sendData.index, this.eventId, sendData).then((res) => {
        if (res.data.result == 0) {
          //this.$refs.map.onPointModify(null);
        }
      });
    },
    

  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
